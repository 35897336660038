import { Component, OnInit } from '@angular/core';
import { ExperienceMessageService } from './services/experience-message.service';
import { AWSService } from './services/aws.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'Account View Login';

  constructor(
    private messageService: ExperienceMessageService,
    private awsService: AWSService  
  ){
  }

  ngOnInit() {
    //Gets the Json file from AEM on app load.
    this.messageService.getExperienceMessages();
  }
}

