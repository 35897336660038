import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, BehaviorSubject } from 'rxjs';
import { AppSettings } from 'src/assets/configs/config';

@Injectable({
    providedIn: 'root'
})
export class ExperienceMessageService {
    cmsPath: string;
    public messages: object = {};
    private messagesFromAEMResponse;
    private messagesFromDocData;

    private _messages$ = new BehaviorSubject({});
    public _messagesState = this._messages$.asObservable();

    constructor(private http: HttpClient) {
        this.cmsPath = AppSettings.CMSEndpoint;
    }

    getExperienceMessages() {
        const aemCall = this.http.request('GET', `${this.cmsPath}/UserMessages.json`);
        const localCall = this.http.get('./assets/data/localUserMessages.json');
        const messagesCalls = forkJoin([aemCall, localCall]);

        messagesCalls.subscribe(([aemCallResponse, localCallResponse]) => {
            if(aemCallResponse && localCallResponse) {
                this.messagesFromAEMResponse = aemCallResponse['messages'];
                this.messagesFromDocData = localCallResponse['messages'];
                const messagesHandler = { ...this.messagesFromAEMResponse, ...this.messagesFromDocData };
                //this.messages = messagesHandler; this can be used if a service needs messages
                messagesHandler['appCopyrightYears'] = messagesHandler['appCopyrightYears'].replace('<CURRENT_YEAR_LOGIC>', new Date().getFullYear().toString());
                this._messages$.next(messagesHandler);
            }
        },
            error => {
                // console.log(error);
                if(!!localCall){
                    this.http.get('./assets/data/localUserMessages.json').subscribe( response => {
                        if(response){
                            this.messagesFromDocData = response['messages'];
							const messagesHandler = { ...this.messagesFromDocData };
							messagesHandler['appCopyrightYears'] = messagesHandler['appCopyrightYears'].replace('<CURRENT_YEAR_LOGIC>', new Date().getFullYear().toString());
                            //this.messages = response['messages']; this can be used if a service needs messages
                            this._messages$.next(messagesHandler);
                        }
                    });

                }
            }
        );
    }
}
