import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";

import { UsernameComponent } from "./username/username.component";
import { SelfRegComponent } from "./self-reg/self-reg.component";
import { ForgotUsernameAvComponent } from './forgot-username-av/forgot-username-av.component';
import { TroubleLoginService } from './services/trouble-login.service';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { HttpClientModule } from "@angular/common/http";
import { FooterComponent } from './footer/footer.component';
import { FooterdescComponent } from './footerdesc/footerdesc.component';
import { AccountviewdescComponent } from './accountviewdesc/accountviewdesc.component';
import { SignupdescComponent } from './signupdesc/signupdesc.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PhoneMaskDirective } from './phone-mask.directive';
@NgModule({
  declarations: [
    AppComponent,
    UsernameComponent,
    SelfRegComponent,
    ForgotUsernameAvComponent,
    FooterComponent,
    FooterdescComponent,
    AccountviewdescComponent,
    SignupdescComponent,
    PhoneMaskDirective,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    BrowserAnimationsModule
  ],
  providers: [TroubleLoginService],
  bootstrap: [AppComponent],
})
export class AppModule { }
