<div class="lplloginmain">
  <div class="loginform" *ngIf="currentState$ | async as currentState">
    <div class="troubleLogin" *ngIf="currentState == WF.TROUBLE_CHOICE">
      <h1 class="text-none">Forgot Username?</h1>
      <span class="your-username">We will email you your username.</span>
      <form #f="ngForm" (ngSubmit)="forgotUsername(f)">
        <div class="form-group">
          <label class="username">Email Address</label>
          <input type="text" id="email" name="email" aria-label="email" #email ngModel />
          <div class="errorTrouble">{{ statusResponse.errorMessage }}</div>
          <div *ngIf="f.form.controls.username as un">
            <div class="alert alert-danger" *ngIf="
                !isBlocked &&
                un?.invalid &&
                (un.dirty || un.touched || f.submitted)
              ">
              Email address is required.
            </div>
          </div>
        </div>
        <div class="btn-wrapper btn-next retriveuser">
          <button class="mr-10 order2" (click)="backtologin($event)" type="submit">
            <fa-icon [icon]="faChevronLeft"></fa-icon>
            BACK
          </button>
          <button type="submit">Retrieve UserName</button>
        </div>
      </form>
    </div>

    <div class="forgot-username-success-av1" *ngIf="currentState == WF.AV1_SUCCESS">
      <h1 style="text-transform: none">Check your inbox.</h1>
      <p class="email-text-av1">Email was sent to {{ userEmailAV1 }}</p>
      <div class="btn-wrapper returnToLogin">
        <button type="submit" (click)="returnToLogin($event)">
          RETURN TO LOGIN
        </button>
      </div>
      <p class="tip-text">
        Tip: Be sure to check your junk folder. If you have trouble, contact your financial professional.
      </p>
    </div>

    <div class="forgot-username-success-av2" *ngIf="currentState == WF.AV2_SUCCESS">
      <h1>Great! Let's Continue.</h1>
      <p class="email-text-av2">Thank you for providing the valid email address.Please click “Next” to proceed.</p>
      <form #f1="ngForm" (ngSubmit)="proceedToAV2(f1)">
        <div class="form-group">
          <label class="username">Email Address</label>
          <input type="text" id="email" name="email" aria-label="user name" [(ngModel)]="userEmailAV2"
            placeholder="Your Email" readonly />
          <div class="errorTrouble">{{ statusResponse.errorMessage }}</div>
          <div class="btn-wrapper btn-next proceed">
            <button type="submit">NEXT</button>
          </div>
        </div>
      </form>
    </div>

    <div class="lpllogo loginbtm">
      <app-footer></app-footer>
    </div>
  </div>

  <div class="loginrightimage col-md-6 col-sm-6 col-xs-12">
    <app-accountviewdesc class="accviewdesc"></app-accountviewdesc>
    <app-footerdesc class="footerdesc" [showLinks]="false"></app-footerdesc>
  </div>
</div>
