import { Injectable } from '@angular/core';
import { User, MOCKUSERS, UserResponseAdapter, UserResponse, SearchUser, GetAccessToken } from './../models/user';
import { Observable, of } from 'rxjs';
import { AppSettings } from 'src/assets/configs/config';
import { delay, catchError, map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { CookieService } from './cookie.service';
import { EndpointService } from './endpoint.service';
import { CONFIG } from './config.constants';
import { AWSService } from './aws.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient,
    private adapter: UserResponseAdapter,
    private awsService: AWSService) { }

  // getAccessToken(oAuthToken: any): Observable<any> {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       Authorization: 'my-auth-token',
  //       access_token:oAuthToken
  //     })
  //   };

  //   const url = AppSettings.APIBase + EndpointService.AUTH_TOKEN_API_END_POINT;
  //   return this.http.post<string>(url, httpOptions)
  //   .pipe( map( data => { data; } ),
  //     catchError(this.handleError<string>('Unable to get temp auth token'))
  //   );
  // }

  getAccessToken(): Observable<string> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        clientId: AppSettings.APIclientId,
        clientSecret: AppSettings.APIclientSecret
      })
    };
    const url = AppSettings.APIBase + EndpointService.ACCESS_TOKEN_API_END_POINT;
    return this.http.post<string>(url,null, httpOptions)
    .pipe( map( data => { return data; } ),
      catchError(this.handleError<string>('Unable to get temp auth token'))
    );
  }

  getUserDetailsFromUserName(user: User,access_token:string): Observable<UserResponse> {
    const response = new UserResponse();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token',
        access_token
      })
    };
    const url = AppSettings.APIBase + EndpointService.PROFILE_VERSION_UN;
    return this.http.post<UserResponse>(url, { username: user.userName }, httpOptions)
      .pipe(
        map(
          data => {
            const res = this.adapter.adapt(data, user.userName, access_token);
            this.setCookie(res);
            return res;
          }
        ),
        catchError(this.handleError<UserResponse>('Create User', response))
      );
  }

  getUserDetailsFromUserNameMock(user: User): Observable<UserResponse> {
    const foundUser = MOCKUSERS.find(x => x.userName === user.userName);

    if (foundUser == null) {
      const invalidUser = new UserResponse();
      invalidUser.isValid = false;
      invalidUser.email = user.userName;
        invalidUser.profileversion = '';
        invalidUser.profileTypeId = 1;
      return of(invalidUser);
    }
    this.setCookie(foundUser);
    return of(foundUser).pipe(delay(0));
  }

  setCookie(user: UserResponse): void {
    if (user && user.isValid === true) {
        const cookieValue = {
                username: user.userName,
                profileversion: user.profileversion,
                profileTypeId: user.profileTypeId,
                mfePilotUser: user?.mfePilotUser,
                clientId: user?.clientId
        };
        var singleLoginValue = JSON.stringify(cookieValue);
        // This is used in both AV1 and V2 project - for Password Page
        CookieService.setCookie('avul_user=' + singleLoginValue);
        // Set Single Login value - to be  used in v2 proj (eg., Forgot Password)
        window.localStorage.setItem(CONFIG.lsSingleLoginUser, singleLoginValue);
    }
  }

  getUserDetailsFromEmail(user: User): Observable<User> {
    const foundUser = MOCKUSERS.find(x => x.email === user.email);

    if (foundUser == null) {
      const invalidUser = new UserResponse();
      invalidUser.isValid = false;
      invalidUser.email = user.email;
      invalidUser.profileversion = '';
      return of(invalidUser);
    }
    return of(foundUser).pipe(delay(15000));
  }


  searchUser(email, mobileNumber, ssnDigits, accessToken, gtoken): Observable<SearchUser> {

    //BEGIN test response
    //
    //var dummyResponse = new SearchUser();
    // dummyResponse.Status = "Success";
    // dummyResponse.activationGUID = "DUMMYGuid";
    // dummyResponse.email = email;
    // dummyResponse.SSNTIN = ssnDigits;
    // return of(dummyResponse);
    //
    //Sample success: { "Status": "Success", "data": { "Email": "av2qa2_4@qalpl.com", "SSN": "2914" }, "activationGUID": "A24D19ED-E344-46DB-B827-FF1DF6D05EF2", "workflowGUID": "510357C7-53F0-4AAC-9C33-201A464A28E8" }
    //END test user

    const response = new SearchUser();

    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     Authorization: 'my-auth-token',
    //     secureKey: AppSettings.AuthAPISecureKey,
    //     bundleId: AppSettings.APIBundleId
    //   })
    // };
    var headers: {} = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    }

    const isOnPrem  = this.awsService.getFlagsAwsAPIRedirect(CONFIG.AwsSelfRegistration) === false
    const url = isOnPrem ? AppSettings.APIBase + EndpointService.SEARCH_USER : AppSettings.awsApiBase + EndpointService.AWS_SEARCH_USER;
    
    if(!isOnPrem) {
      headers = {
        'x-auth-access-Token': accessToken
      }
    }

    var data = {
      "email": email,
      "mobileNumber": mobileNumber,
      "ssnDigits": ssnDigits,
      "gtoken": gtoken
    }
    return this.http.post<SearchUser>(url, data, { headers: headers })
      .pipe(
        map(
          data => {
            return data;
          }
        ),
        catchError(this.handleError<SearchUser>('Search User', response))
      );
  }
  getAccessTokenString(): Observable<GetAccessToken> {

    //BEGIN test response
    //
    // var dummyResponse = new GetAccessToken();
    // dummyResponse.access_token = "";
    // return of(dummyResponse);
    //
    //END test user

    const response = new GetAccessToken();
    var headers: {} = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic SW52ZXN0b3JBUElfT2F1dGhfUGFzc0NsaWVudF9DUDpMUExwYXNzdzByZFNlY3JldA=='
    }

    const isOnPrem  = this.awsService.getFlagsAwsAPIRedirect(CONFIG.AwsSelfRegistration) === false
    const url = `${AppSettings.APIBase}${isOnPrem ? EndpointService.SEARCH_USER_TOKEN : EndpointService.AWS_SEARCH_USER_TOKEN}`
    
    if(!isOnPrem){
      
      const headers = {
        'clientId': AppSettings.clientId,
        'clientSecret': AppSettings.clientSecret
      }

      return this.http.get<GetAccessToken>(url, { headers: headers }).pipe(
        map(
          data => {
            return data;
          }
        ),
        catchError(this.handleError<GetAccessToken>('Search User', response))
      );
    }

    const params = new HttpParams()
      .set('grant_type', 'password')
      .set('password', AppSettings.accesspassword)
      .set('username', 'invselfregister')
      .set('scope', '/investor-estatement-rtf-api/investor/searchuser');

    return this.http.post<GetAccessToken>(url, params, { headers: headers })
      .pipe(
        map(
          data => {
            return data;
          }
        ),
        catchError(this.handleError<GetAccessToken>('Search User', response))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {

      this.errorLog(`${operation} failed: ${error.message}`);

      // return Failed SvcResponse.
      // return of(result as T);
      return of(null);
    };
  }
  private errorLog(message: string): void {
    // console.error(message);
  }

  private log(message: any): void {
    // console.log(message);
  }
}
