import { AppSettings } from "src/assets/configs/config";
import { Injectable } from "@angular/core";
import { EndpointService } from "../services/endpoint.service";

export class User {
  userName: string;
  email: string;
}

export class UserResponse {
  userName: string;
  profileversion: string;
  profileTypeId: number;
  email: string;
  isValid: boolean;
  login?: string;
  mfePilotUser?:number;
  clientId?:number;
  autoEnrollmentStatus?: string;
  access_token:string;
}

export class GetAccessToken {
  access_token: string;
}

export class SearchUser {
  Status: string;
  Message: string;
  Error: string;

  GUID: string;
  activationGUID: string;
  Profile: string;

  FirstName: string;
  ClientID: string;
  HomePhone: string;
  MiddleName: string;
  ClientGroupName: string;
  SSNTIN: string;
  RepID: string;
  LastName: string;
  ClientTypeID: number;
  MobilePhone: number;
  ClientTypeDesc: string;
  email: string;
  ClientGroupID: number;
  UserName: string;
  user: {
    UserName: ""
  }
}

@Injectable({
  providedIn: "root",
})
export class UserResponseAdapter {
  adapt(item: any, userName: string,access_token: string): UserResponse {
    const userFromService = item;
    const user = new UserResponse();
    user.userName = userName;
    user.email = userFromService.email;
    user.isValid = userFromService.IsValid === 0;
    user.profileversion = userFromService.profileVersion;
    user.profileTypeId = userFromService.profileTypeId;
    user.mfePilotUser = userFromService.mfePilotUser;
    user.login =  AppSettings.AV1Login;
    user.access_token= access_token;
    user.autoEnrollmentStatus= userFromService.autoEnrollmentStatus;
    if(userFromService.autoEnrollmentStatus !== "0" && userFromService.autoEnrollmentStatus !== "" && userFromService.autoEnrollmentStatus !== undefined && userFromService.autoEnrollmentStatus !== null ){
        user.login = AppSettings.av2ActivationURL+userFromService.autoEnrollmentStatus+"&isautoenroll=true";
        console.log("User-login:",user.login);
    }
    else if(userFromService.mfePilotUser == 1 && userFromService.profileVersion === "2.0"){
      user.login = AppSettings.APIBase + EndpointService.MFE_REDIRECT_URL;
    }
    else if(userFromService.profileVersion === "2.0") {
        user.login = AppSettings.AV2Login;
    }
    //remove username in qs
    //user.login += "?sluser=" + userName.replace("&", "%26") + "&slmode=true";
    return user;
  }
}


export const MOCKUSERS: UserResponse[] = [
  //   {
  //     userName: "av1",
  //     email: "av1@a.com",
  //     profileversion: "1.0",
  //     isValid: true,
  //     login: AppSettings.AV1Login,
  //   },
  //   {
  //     userName: "av2",
  //     email: "av2@a.com",
  //     profileversion: "2.0",
  //     isValid: true,
  //     login: AppSettings.AV2Login,
  //   },
  //   {
  //     userName: "av2",
  //     email: "av21@a.com",
  //     profileversion: "2.1",
  //     isValid: true,
  //     login: AppSettings.AV2Login,
  //   },
  //   { userName: "", email: "", profileversion: "", isValid: false },
];

