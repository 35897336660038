declare var configEnvironment: any;

if (configEnvironment.environment != 'local') {
    configEnvironment.apiBase = '#{AV.Auth.SingleLogin.APIBase}';
    configEnvironment.bundleId = '#{InvestorExperience.BundleId}';
    configEnvironment.atop = '#{getaccesstoken.password}';
    configEnvironment.authAPISecureKey = '#{AV.Auth.SingleLogin.AuthAPI.SecureKey}';
    configEnvironment.av1Login = '#{AV.Auth.SingleLogin.Url.AV1Login}';
    configEnvironment.av2ActivationURL = '#{AV.Auth.SingleLogin.Url.SelfRegActivation}?guid=';
    configEnvironment.av2AutoEnrollment = '#{AV.Auth.SingleLogin.Url.AV2AutoEnrollment}';
    configEnvironment.av2Login = '#{AV.Auth.SingleLogin.Url.AV2Login}';
    configEnvironment.av2MFELogin = '#{AV.Auth.SingleLogin.Url.AV2MFELogin}';
    configEnvironment.awsApiBase = '#{InvestorAuth.AwsAPIBase}';
    configEnvironment.clientId = '#{InvestorExperience.ClientId}';
    configEnvironment.clientSecret = '#{InvestorExperience.ClientSecret}';
    configEnvironment.cmsEndpoint = '#{InvestorAuth.CMSEndpoint}';
    configEnvironment.cookieDomain = '#{InvestorAuth.CookieDomain}';
    configEnvironment.encryptedKey = '#{InvestorAuth.EncryptedKey}';
    configEnvironment.SITE_ID = '#{SITE_ID}';
}

export class AppSettings {

    public static APIBase = configEnvironment.apiBase;
    public static APIBundleId = configEnvironment.bundleId;
    public static APIclientId = configEnvironment.clientId;
    public static APIclientSecret = configEnvironment.clientSecret;
    public static AV1Login = configEnvironment.av1Login;
    public static AV2AUTOENROLLMENT = configEnvironment.av2AutoEnrollment;
    public static AV2Login = configEnvironment.av2Login;
    public static AV2MFELogin = configEnvironment.av2MFELogin;
    public static AppName = 'AccountView';
    public static AuthAPISecureKey = configEnvironment.authAPISecureKey;
    public static CMSEndpoint = configEnvironment.cmsEndpoint;
    public static Env = configEnvironment.environment;
    public static LogIsEnabled: boolean = configEnvironment.environment !== 'prod';
    public static SelfRegistrationIsEnabled: boolean = configEnvironment.selfRegistrationIsEnabled.toLowerCase() === 'true';
    public static accesspassword = configEnvironment.atop;
    public static av2ActivationURL = configEnvironment.av2ActivationURL;
    public static awsApiBase = configEnvironment.awsApiBase;
    public static clientId = configEnvironment.clientId;
    public static clientSecret = configEnvironment.clientSecret;
    public static cookieDomain = configEnvironment.cookieDomain;
    public static encryptedKey = configEnvironment.encryptedKey;
    public static sitekey = configEnvironment.SITE_ID;
}
