import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { UsernameComponent } from "./username/username.component";
import { ForgotUsernameAvComponent } from './forgot-username-av/forgot-username-av.component';
import { SelfRegComponent } from "./self-reg/self-reg.component";

const routes: Routes = [
  { path: "login", component: UsernameComponent },
  { path: 'forgot-username', component: ForgotUsernameAvComponent },
  { path: "selfreg", component: SelfRegComponent },
  { path: 'migrate', loadChildren: () => import('./modules/self-mig/self-mig.module').then(m => m.SelfMigModule) },

  { path: "**", component: UsernameComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
