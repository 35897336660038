import { Injectable } from '@angular/core';
//import { environment } from 'assets/environments/environment';
import { AppSettings } from '../../../../src/assets/configs/config';

@Injectable({
    providedIn: 'root'
})
export class LoggerService {

    constructor() { }
    group(msg: string) {
        if (AppSettings.LogIsEnabled === true) {
            console.group(msg);
        }
    }

    groupEnd() {
        if (AppSettings.LogIsEnabled === true) {
            console.groupEnd();
        }
    }

    log(...data: any[]) {
        try {
            if (AppSettings.LogIsEnabled === true) {
                const args = Array.prototype.slice.call(arguments);
                console.log.apply(console, args);
            }
        } catch {
            console.log('error logging');
        }
    }
}
