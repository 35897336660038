import { Injectable } from '@angular/core';
import { AppSettings } from 'src/assets/configs/config';

@Injectable({
    providedIn: 'root'
})
export class CookieService {

    static setCookie(value: string) {

        const now = new Date();
        now.setDate(now.getDate() + 2);
        document.cookie = value + ';expires=' + now.toUTCString() + ';path=/;Domain=' + AppSettings.cookieDomain + ';SameSite=Strict';


    }

    static setCookie2h(value: string) {
        const now = new Date();
        now.setHours(now.getHours() + 2);
        document.cookie = value + ';expires=' + now.toUTCString() + ';path=/;Domain= ' + AppSettings.cookieDomain + ';SameSite=Strict';
    }

    static readCookie(key: string) {
        const allCookie = document.cookie;
        const cookies = allCookie.split(';');
        const cookie = cookies.find(x => {
            return x.indexOf(key) > -1;
        });
        return cookie;
    }

    static removeCookie(value: string) {
        const now = new Date();
        now.setDate(now.getDate() - 7);
        document.cookie = value + ';expires=' + now.toUTCString() + ';path=/;Domain=' + AppSettings.cookieDomain + ';SameSite=Strict';
    }


}