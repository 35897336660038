import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppSettings } from 'src/assets/configs/config';
import { ExperienceMessageService } from '../services/experience-message.service';
import { ExperienceMessageKeys } from '../shared/enums/message-service-keys.enum';
import { StaticService } from '../services/static.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footerdesc',
  templateUrl: './footerdesc.component.html',
  styleUrls: ['./footerdesc.component.scss'],
})
export class FooterdescComponent implements OnInit, OnDestroy {
  messagesSubcription   : Subscription;
  @Input() showLinks: boolean = true;
  linkUsageAgreement = AppSettings.CMSEndpoint + '/' + StaticService.termsOfUse;
  linkAccountViewDisclosures = AppSettings.CMSEndpoint + '/' + StaticService.fullDisclosure;
  copyrightYear: string;
  constructor(
    private messageService: ExperienceMessageService){
  }

  ngOnInit(): void {
    this.getMessages();
  }

  getMessages() {
    this.messagesSubcription = this.messageService._messagesState.subscribe( messages => {
        this.copyrightYear = messages[ExperienceMessageKeys.appCopyrightYears];
    });
  }

  ngOnDestroy() {
    if(this.messagesSubcription) {
        this.messagesSubcription.unsubscribe();
    }
  }
}
