import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, of } from 'rxjs';
import { ValidateEmailRestReqModel } from '../models/validate-email-rest-req.model';
import { ValidateEmailRestRespModel } from '../models/validate-email-rest-resp.model';
import { EndpointService } from '../services/endpoint.service';
import { AppSettings } from '../../assets/configs/config';

export enum WorkflowState {
  TROUBLE_CHOICE = 'TROUBLE_CHOICE',
  AV1_SUCCESS = 'AV1_SUCCESS',
  AV2_SUCCESS = 'AV2_SUCCESS'
};

@Injectable({
  providedIn: 'root'
})
export class TroubleLoginService {

  constructor(public _http: HttpClient) { }
  // PRIVATE DATA
  private _currentState = new BehaviorSubject<WorkflowState>(WorkflowState.TROUBLE_CHOICE);

  // PUBLIC DATA
  public currentState$ = this._currentState.asObservable();

  setWorkflowStep(step: WorkflowState) {
    this._currentState.next(step);
  }

  sendAV1Email(email: string) {

    const url = AppSettings.AV1Login + '/LogOn/LogOn/SLSendAV1Email';

    //const url = "http://qa.myaccountviewonline.com/AccountView" + '/LogOn/LogOn/ValidateEmailForForgotUsername';

    this._http.request<ValidateEmailRestRespModel>('POST', url, {
      body: { 'email': email }
    }).subscribe(data => {

    }, err => {

    });
  }

  validateEmailMock(emailReq: ValidateEmailRestReqModel): any {
    return of({ email: emailReq.emailId, profileversion: '1.0', IsValid: 0 });
  }

  validateEmail(email: ValidateEmailRestReqModel) {

    const secureKey = AppSettings.AuthAPISecureKey;
    const bundleId = AppSettings.APIBundleId;

    const headers = { headers: { bundleId, secureKey } };

    const url = AppSettings.APIBase + EndpointService.PROFILE_VERSION_EMAIL;

    //console.log(email);
    return this._http.request<ValidateEmailRestRespModel>('POST', url, {
      body: {
        'email': email
      },
      ...headers
    });

  }
}
