export const CONFIG = {

    //Query string keys
    qsSelfMig: 'avulsm',
    qsSelfReg: 'selfreg',

    //Local storage
    // Product_App_Feature_Key: InvExp_AutWeb_UniversalLogin_User
    lsSingleLoginUser: 'ie_aw_ul_user',
    lsSelfMigOrSelfRegUser: 'avulselfuser',
    lsSelfMigDone: 'avulsmdone',
    lsSelfRegDone: 'avulsrdone',
    AwsSelfRegistration: 'AWS_SelfRegistration'
};
