
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'src/app/services/cookie.service';
import { LoggerService } from '../sharedService/logger.service';

@Injectable({
    providedIn: 'root'
})
export class LogOffService {

    static readonly accessToken = 'x-auth-access-token';

    constructor(private loggerService: LoggerService, private http: HttpClient) { }

    // Similar to deauthenticate in V2 project.
    // File in v2 proj: src/app/core/services/WebServiceConsumers/auth.service.ts
    // Clears specific localstorage , sessionstorage, cookie.
    // Cookie remove for iPlanetDirectoryPro
    // Remove all localstorage except whitelisted items;
    // Remove all sessionstorage;
    public deauthenticate() {

        try {

            // IE-specific authentication cache clear:
            // document.execCommand("ClearAuthenticationCache");

            this.loggerService.group('logOff-deauthenticate');

            //Cookie - remove only
            CookieService.removeCookie('iPlanetDirectoryPro =');

            //log after
            this.loggerService.log('before clear');
            this.logStorage();

            //SessionStorage - clear all
            sessionStorage.clear();

            //Local Storage
            this.clearLocalStorage();

            //log after
            this.loggerService.log('after clear');
            this.logStorage();

            this.loggerService.groupEnd();
        } catch (error) {
            this.loggerService.log('error logOut', error);
        }
    }

    private logStorage() {
        this.loggerService.group('logLS');
        this.loggerService.log('ss length:' + sessionStorage.length);
        this.loggerService.log('ls length:' + localStorage.length);

        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            this.loggerService.log(key);
        }
        this.loggerService.groupEnd();
    }

    private clearLocalStorage() {
        const whitelistedItems = ['invDevice', 'avul_user','ie_aw_ul_user','rememberMe'];

        const saved = {};

        for (let i = 0; i < localStorage.length; i++) {
            const localStorageKey = localStorage.key(i);
            whitelistedItems.map(wlItem => {
                if (localStorageKey.includes(wlItem)) { //key name contains
                    saved[localStorageKey] = localStorage.getItem(localStorageKey);
                }
            });
        }

        localStorage.clear();

        for (const [key, value] of (<any>Object).entries(saved)) {
            localStorage.setItem(key, value);
        };

    }
}

