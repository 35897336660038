import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class EndpointService {

  static PROFILE_VERSION_EMAIL = '/investor-muleeditprofile-api-v2/investor/user/profileversionforemail';
  static PROFILE_VERSION_UN = '/LPLInvestorExperienceWebApi/investorprofile/profileversionforusername';

  static SEARCH_USER_TOKEN = '/esb/lpl/resource/getaccesstoken';
  static SEARCH_USER = '/investor-estatement-rtf-api/investor/searchuser';

  static selfMigValidate = '/Home/home/selfmigvalidateuser';
  static av1ValidateSession = '/LogOn/LogOn/IsSessionExpired';

  static CMSUserMessagesFile = "UserMessages.json";

  //TODO : cleanup - NOT USED ANYMORE
  static CREATE_PW = '/investor-estatement-rtf-api/investor/registrar';
  static SelfregValidateOtp = '/investor-estatement-rtf-api/investor/selfregvalidateotp';
  static SelfregOtpChoice = '/investor-estatement-rtf-api/investor/selfregotpchoice';
  static selfregsendotp = '/investor-estatement-rtf-api/investor/selfregsendotp';
  static VALIDATE_USER = '/investor/user/new/validateuser';
  // static TEMP_AUTH_TOKEN_API_END_POINT = '/';
  static ACCESS_TOKEN_API_END_POINT = '/am/oauth2/realms/root/access_token';

  static MFE_REDIRECT_URL ='/LPLInvestorExperienceWebApi/investorprofile/av2mferedirect';
  static AWS_SEARCH_USER_TOKEN  = '/am/oauth2/realms/root/access_token';
  static AWS_SEARCH_USER  = '/api/investor/enrollment-process/enrollment/openapi/searchuser';
  static ACCTVIEW_SWITCH = '/investor-analytics-api/investor/acctview-switch';

  constructor() { }
}

