import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { AuthService } from './../services/auth.service';
import { UIStatus } from './../models/models';
import { User, UserResponse } from './../models/user';
import { Router, ActivatedRoute } from '@angular/router';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AppSettings } from 'src/assets/configs/config';
import { CookieService } from './../services/cookie.service';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { LogOffService } from 'src/core/services/logOffService/logoff.service';
import { LoggerService } from 'src/core/services/sharedService/logger.service';
import { CONFIG } from '../services/config.constants';
import { ExperienceMessageService } from '../services/experience-message.service';
import { ExperienceMessageKeys } from '../shared/enums/message-service-keys.enum';

@Component({
  selector: 'app-username',
  templateUrl: './username.component.html',
  styleUrls: ['./username.component.scss'],
})
export class UsernameComponent implements OnInit, OnDestroy {
  @ViewChild('inputUserName', { static: true }) inputUserName: ElementRef;
  messagesSubcription   : Subscription;
  // Default values.
  isSelfRegDone = false;
  faChevronRight = faChevronRight;
  selectedUser: User = new User();
  statusResponse: UIStatus = {
    statusMessage: '',
    errorMessage: '',
    loadingMessage: '',
    isLoading: false,
  };

  // Setup fa icons
  faCircleNotch = faCircleNotch;
  subs: Subscription;
  selfRegistrationIsEnabled: boolean;
  setpass: any;
  isSelfMigDone = false;
  tilte: string;
  profileUpgradeSuccessTitle: string;
  profileUpgradeSuccessMsg: string;
  accountSetupSuccessTitle: string;
  accountSetupSuccessMsg: string;
  message: string;
  errorSystem: string;
  signupTitle = '';
  signupMsg = '';
  signupBtnMsg = '';
  showLightBulb: boolean;
  selfRegistartionFlow: boolean;
  selfMigrationFlow: boolean;
  // oAuthTokenSub: Subscription;
  oAuthAccessTokenSub:Subscription;

  constructor(
    private router: Router,
    private authService: AuthService,
    public fb: FormBuilder,
    private route: ActivatedRoute,
    private logOffService: LogOffService,
    private loggerService: LoggerService,
    private messageService: ExperienceMessageService
  ) {

  }

  ngOnInit(): void {
    //set by v2 project activation process when selfmigration is done.

    //do - dev override
    const queryParamDevOverrideSelfMigDone = 'dosm';
    const queryParamDevOverrideSelfRegDone = 'dosr';
    const queryParamDevOverrideDoClear = 'doclear';

    this.route.queryParamMap.subscribe(queryParams => {
      // dev overriddes to test congrats page without completing self reg or self mig
      const devOverrideSelfMigDone = queryParams.get(queryParamDevOverrideSelfMigDone) === 'true';
      const devOverrideSelfRegDone = queryParams.get(queryParamDevOverrideSelfRegDone) === 'true';

      //if self reg flow -> this will be set by v2 project after completing self Registration
      const hasLSValueForSelfReg = window.localStorage.getItem(CONFIG.lsSelfRegDone) === 'true';
      this.isSelfRegDone = hasLSValueForSelfReg || devOverrideSelfRegDone;

      //if self migration flow -> this will be set by v2 project after completing self Migration
      const hasLSValueForSelfMig = window.localStorage.getItem(CONFIG.lsSelfMigDone) === 'true';
      this.isSelfMigDone = hasLSValueForSelfMig || devOverrideSelfMigDone;

      //Retrieve all localstorage values before deauthenticate call
      //since deauthenticate will remove localstorage values
      const devOverrideClear = queryParams.get(queryParamDevOverrideDoClear) === 'true';

      this.loggerService.log('DeAuth: ' + devOverrideClear);
      //if (devOverrideClear)
        this.logOffService.deauthenticate(); // clear on logout

    });

    this.setFlowVariables();
    this.setLightBulbArea();

    this.inputUserName.nativeElement.focus();
    const inputUserNameValue = this.inputUserName.nativeElement.value;
    this.selfRegistrationIsEnabled = false;//Force hide for Nov Release. AppSettings.SelfRegistrationIsEnabled;
    CookieService.removeCookie('loggedIn =');
  }

  setFlowVariables(){
    this.selfMigrationFlow = !this.isSelfRegDone && this.isSelfMigDone;
    this.selfRegistartionFlow = this.isSelfRegDone && !this.isSelfMigDone;
  }

  setLightBulbArea() {
    if (!this.isSelfRegDone && !this.isSelfMigDone) {
      //not from congrats - normal login
      this.showLightBulb = false;
      //title and message is hidden now. so below 2 lines are no longer applicable.
      this.tilte = 'Welcome to the new Account View login page!';
      this.message = 'Your saved password may not autofill. You can manually enter or reset it on the next page.';
      this.signupBtnMsg = 'Sign up';
      this.signupMsg = 'Account View is a safe and secure way to view balances, review transactions, and see your financial progress.';
      this.signupTitle = 'New? Sign up for Account View!';
    } else {
      // Self Reg or Self Mig flow
      // title and message comes from messageService
      this.showLightBulb = true;
    }
    this.getMessages();
  }

  getMessages() {
    this.messagesSubcription = this.messageService._messagesState.subscribe( messages => {
      this.errorSystem = messages[ExperienceMessageKeys.systemNotAvailable];
      if(this.selfMigrationFlow){
        this.tilte = messages[ExperienceMessageKeys.aWebCongratsTitleSelfMig];
        this.message = messages[ExperienceMessageKeys.aWebCongratsMessageSelfMig];
      }
      if(this.selfRegistartionFlow){
        this.tilte = messages[ExperienceMessageKeys.aWebCongratsMessageAll];
        this.message = messages[ExperienceMessageKeys.aWebCongratsTitleAll];
      }
    });
  }

  hasValue(): boolean {
    const inputUserNameValue = this.inputUserName.nativeElement.value;

    if (
      this.selectedUser.userName === null ||
      this.selectedUser.userName === undefined ||
      this.selectedUser.userName.trim() === ""
    ) {
      if (inputUserNameValue.trim() === "") {
        return false;
      } else {
        // In some cases like browser back, auto fill, ng doesnot fill in this value.
        // So access the input el directly and get value.
        this.selectedUser.userName = inputUserNameValue;
        return true;
      }
    } else {
      return true;
    }
  }

  onUserNameNext(): void {
    if (this.hasValue() === false) {
      this.statusResponse.errorMessage = "Please enter the username";
      return;
    }

    this.selectedUser.userName = this.selectedUser.userName.trim();

    this.statusResponse.errorMessage = "";
    this.statusResponse.isLoading = true;
 
    this.oAuthAccessTokenSub = this.authService.getAccessToken().subscribe(data =>{
        this.subs = this.authService
        .getUserDetailsFromUserName(this.selectedUser, data['access_token'])
        .subscribe(
          (userResponse: UserResponse) => {
            if (userResponse == null || userResponse == undefined) {
              // server error
              this.statusResponse.isLoading = false;
              this.statusResponse.errorMessage = this.errorSystem;
              return;
            }
  
            if (userResponse.isValid) {
 
              if(userResponse.mfePilotUser && userResponse.profileversion  === "2.0"){
  
                this.statusResponse.isLoading = true;
                this.redirectToMFE(userResponse);
                
              }else{
                // success
                // keep loading icon even if page is redirected.
                this.statusResponse.isLoading = true;
                window.open(userResponse.login, "_top");
              }
             
            } else {
              // User not found
              this.statusResponse.isLoading = false;
              // this.statusResponse.errorMessage =
              //   "Username not found, please retry with correct username";
              window.open(AppSettings.AV1Login, "_top");
            }
          },
          (error: any) => {
            // server error
            this.statusResponse.isLoading = false;
            this.statusResponse.errorMessage = this.errorSystem;
          }
        ),
        (error: any) => {
          // server error
          this.statusResponse.isLoading = false;
          this.statusResponse.errorMessage = this.errorSystem;
        }
    })
  }

  redirectToMFE(userResponse: UserResponse) {
      let mapForm = document.createElement("form");
      mapForm.target = "_self";
      mapForm.method = "POST";
      mapForm.action= userResponse.login;
      const props = ['access_token','userName'];
      props.forEach((prop)=>{
        let mapInput = document.createElement("input");
        mapInput.type = "text";
        mapInput.name = prop;
        mapInput.value = userResponse[prop];
        mapForm.appendChild(mapInput);
      })
      document.body.appendChild(mapForm);
      mapForm.submit();
  }

  forgotUsername(event): void {
    event.preventDefault();
    this.router.navigateByUrl("/forgot-username");
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    if (this.subs != null) {
      this.subs.unsubscribe();
    }
    if (this.oAuthAccessTokenSub != null) {
      this.oAuthAccessTokenSub.unsubscribe();
    }
    if(this.messagesSubcription) {
        this.messagesSubcription.unsubscribe();
    }
  }
}
