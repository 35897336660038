import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subscription, Observable } from "rxjs";
import { UIStatus } from "./../models/models";
import { User } from "./../models/user";
import { NgForm } from "@angular/forms";
import {
  TroubleLoginService,
  WorkflowState,
} from "../services/trouble-login.service";
import { ValidationMessages } from "../validators/validation.messages";
import { AppSettings } from "../../../src/assets/configs/config";
import { CookieService } from "../services/cookie.service";
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { EndpointService } from "../services/endpoint.service";
import { AuthService } from "../services/auth.service";

@Component({
  selector: "app-forgot-username-av",
  templateUrl: "./forgot-username-av.component.html",
  styleUrls: ["./forgot-username-av.component.scss"],
  providers: [TroubleLoginService],
})
export class ForgotUsernameAvComponent implements OnInit, OnChanges, OnDestroy {
  faChevronLeft = faChevronLeft;
  selectedUser: User = new User();
  loginSubscription: Subscription;
  isBlocked: boolean = false;
  userEmailAV1: string = undefined;
  userEmailAV2: string = undefined;
  currentState$: Observable<WorkflowState>;
  WF = WorkflowState;
  url: any;
  private _subs: Subscription;
  private userName: string = undefined;
  private mfePilotUser;
  statusResponse: UIStatus = {
    statusMessage: "",
    errorMessage: "",
    loadingMessage: "",
    isLoading: false,
  };

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private srvc: TroubleLoginService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.currentState$ = this.srvc.currentState$;
    this.srvc.setWorkflowStep(WorkflowState.TROUBLE_CHOICE);
  }

  ngOnChanges() {
    this.currentState$ = this.srvc.currentState$;
  }

  forgotUsername(f: NgForm) {
    if (f.valid) {
      const { email } = f.form.controls;

      if (email.value === "" || email.value == undefined) {
        this.statusResponse.errorMessage = ValidationMessages.emailId.required;
        return false;
      }

      // this._subs = this.srvc.validateEmailMock(email.value).subscribe((rsp) => {
      this._subs = this.srvc.validateEmail(email.value).subscribe((rsp) => {
        if (rsp && rsp !== undefined) {
          const user = rsp;
          // console.log("response");
          // console.log(user);
          if (user.IsValid === 0) {
            // Set Cookie to be used in Av1 and Av2.
            const cookieValue = {
              username: user.username,
              profileversion: user.profileversion,
            };
            CookieService.setCookie("avul_user=" + JSON.stringify(cookieValue));

            if (user.profileversion === "1.0") {
              // console.log("profile type id is 1");
              this.srvc.setWorkflowStep(WorkflowState.AV1_SUCCESS);
              this.srvc.sendAV1Email(email.value);
              this.userEmailAV1 = user.email;
              this.statusResponse.errorMessage = undefined;
              this.cdr.detectChanges();
            } else if (
              user.profileversion === "2.0" ||
              user.profileversion === "2.1"
            ) {
              // console.log("profile type id is 0");
              this.srvc.setWorkflowStep(WorkflowState.AV2_SUCCESS);
              this.userEmailAV2 = user.email;
              this.userName = user.username;
              this.mfePilotUser = user.mfeUser;
              this.statusResponse.errorMessage = undefined;
              this.cdr.detectChanges();
            } else {
              this.statusResponse.errorMessage =
                ValidationMessages.system.systemError;
              return false;
            }
          } else {
            this.statusResponse.errorMessage =
              ValidationMessages.emailId.notFound;
            return false;
          }
        } else {
          this.statusResponse.errorMessage =
            ValidationMessages.system.systemError;
          return false;
        }
      }, (error: any) => {
        // server error
        this.statusResponse.errorMessage = ValidationMessages.system.systemError;
        return false;
      });
    } else {
      //console.log("form not valid");
    }
  }

  redirectToMFE(userResponse: any) {
    let mapForm = document.createElement("form");
    mapForm.target = "_self";
    mapForm.method = "POST";
    mapForm.action= userResponse.login;
    // user.login = AppSettings.APIBase + EndpointService.MFE_REDIRECT_URL;
    const props = ['access_token','userName'];
    props.forEach((prop)=>{
      let mapInput = document.createElement("input");
      mapInput.type = "text";
      mapInput.name = prop;
      mapInput.value = userResponse[prop];
      mapForm.appendChild(mapInput);
    })
    document.body.appendChild(mapForm);
    mapForm.submit();
}

  proceedToAV2(f: NgForm) {
    if (f.valid) {
      const { email } = f.form.controls;
      if (email.value === "" || email.value == undefined) {
        this.statusResponse.errorMessage = ValidationMessages.emailId.required;
        return false;
      }

      if (email.value === this.userEmailAV2) {
        if (this.mfePilotUser) {
          this.authService.getAccessToken().subscribe((data) => {
            this.url = AppSettings.APIBase + EndpointService.MFE_REDIRECT_URL;
            this.redirectToMFE({login: this.url, access_token: data['access_token'], userName: this.userName});
          })
        } else {
          this.url = AppSettings.AV2Login + "?sluser=" + this.userName + "&slmode=true";
          window.open(this.url, "_self");
        }
        return;
      } else {
        this.statusResponse.errorMessage = ValidationMessages.emailId.notFound;
        this.cdr.detectChanges();
        return false;
      }
    } else {
      // console.log("form not valid");
    }
  }

  backtologin(event): void {
    event.preventDefault();
    this.router.navigateByUrl("/login");
  }

  validEmailCheck(inp: string): boolean {
    return /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/.test(inp);
  }

  returnToLogin(event) {
    event.preventDefault();
    this.router.navigateByUrl("/login");
  }

  ngOnDestroy() {
    if (this._subs != null) {
      this._subs.unsubscribe();
    }
  }
}
