import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppSettings } from '../../assets/configs/config';
import { EndpointService } from './endpoint.service';
import { pipe } from 'rxjs';

interface AWS_RESPONSE_FLAGS {
  ConfigKey: string;
  ConfigValue: string;
}
@Injectable({
  providedIn: 'root'
})
export class AWSService  {
  flagsAwsAPIRedirectState = {}
  url = AppSettings.APIBase + EndpointService.ACCTVIEW_SWITCH;

  constructor(
    private httpClient: HttpClient,
  ) {}

  public getAWSFlags() {
    const { APIBundleId, encryptedKey } = AppSettings;
    const headers = {
      headers: { bundleId: APIBundleId, secureKey: encryptedKey },
    };
    
    return this.httpClient.get<any>(this.url, headers).pipe(
      map(
        (resp) => {
          if (resp) {
            
            let flagsAPIRedirectAWS = {};
            let configKeyValues: AWS_RESPONSE_FLAGS[] = resp.data;

            configKeyValues.forEach((config: AWS_RESPONSE_FLAGS) => {
              if (!flagsAPIRedirectAWS[config.ConfigKey])
                flagsAPIRedirectAWS[config.ConfigKey] = Boolean(Number(config.ConfigValue));
            });
           
            return flagsAPIRedirectAWS;
          }
        },
        (err) => {
          console.log('Error -', err);
          return 'AWS error';
        })
    )
  }

  public setFlagsAwsAPIRedirect(flagsAwsAPIRedirect: any) {
    this.flagsAwsAPIRedirectState = flagsAwsAPIRedirect;
  }

  public getFlagsAwsAPIRedirect(key: string) {
    if (this.flagsAwsAPIRedirectState && this.flagsAwsAPIRedirectState[key] !== undefined) {
      return this.flagsAwsAPIRedirectState[key];
    }
    return false;
  }
}
