import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StaticService {

    static termsOfUse = 'accountViewTermsOfUse.html';
    static fullDisclosure = 'fulldisclosure.html';
    // add more static links here

    constructor() { }
}
