<div class="container-fluid">
    <div class="lplloginmain row">
        <div class="loginform col-md-6 col-sm-6 col-xs-12">
            <div class="welcome-title">
                <h1>
                    Create your account
                    <span>Let’s find you in our system!</span>
                </h1>
            </div>
            <span class="same-info"
                >Please use the same information you provided to your financial
                professional.</span
            >
            <form method="post" [formGroup]="myForm">
                <div class="form-input-box">
                    <label id="usernamelabel" class="username">Email</label>
                    <input
                        aria-label="Email Address"
                        type="email"
                        (keyup)="changeemail(email)"
                        [(ngModel)]="email"
                        formControlName="emailId"
                        name="inputUserName"
                        [ngClass]="{ 'reg-submitted': submitted }"
                    />

                    <div
                        class="error"
                        *ngIf="
                            f.emailId.touched &&
                            f.emailId.invalid &&
                            submitted &&
                            f.emailId.errors &&
                            f.emailId.errors.required
                        "
                    >
                        {{ errorMessages.emailRequired }}
                    </div>
                    <div
                        class="error"
                        *ngIf="
                            (f.emailId.touched &&
                                submitted &&
                                f.emailId.errors &&
                                f.emailId.errors.email) ||
                            (submitted && emailshowmsg)
                        "
                    >
                        {{ errorMessages.emailInvalid }}
                    </div>
                </div>

                <div class="form-input-box">
                    <label id="labelPhone" class="username">Phone</label>
                    <input
                        appPhoneMask
                        id="tel"
                        [inputId]="'tel'"
                        [preValue]="myForm.value.mobileNumber"
                        [phoneControl]="myForm.controls['mobileNumber']"
                        aria-label="Phone Number"
                        formControlName="mobileNumber"
                        maxlength="14"
                        [(ngModel)]="phone"
                        name="inputPhone"
                        [ngClass]="{ 'reg-submitted': submitted }"
                    />
                    <div
                        class="error"
                        *ngIf="
                            submitted &&
                            f.mobileNumber.invalid &&
                            f.mobileNumber.errors &&
                            f.mobileNumber.errors.pattern
                        "
                    >
                        {{ errorMessages.phonePattern }}
                    </div>
                    <div
                        class="error"
                        *ngIf="
                            submitted &&
                            f.mobileNumber.invalid &&
                            f.mobileNumber.errors &&
                            f.mobileNumber.errors.required
                        "
                    >
                        {{ errorMessages.phoneRequired }}
                    </div>
                </div>

                <div class="form-input-box">
                    <label id="labelSSN" type="number" class="username ssn-zip"
                        >Last Four Digits of SSN</label
                    >
                    <input
                        aria-label="SSN"
                        maxlength="4"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        type="text"
                        formControlName="ssnDigits"
                        name="inputSSN"
                        [ngClass]="{ 'reg-submitted': submitted }"
                    />
                    <div
                        class="error"
                        *ngIf="
                            f.ssnDigits.invalid &&
                            submitted &&
                            f.ssnDigits.errors.required
                        "
                    >
                        {{ errorMessages.ssnRequired }}
                    </div>
                    <div
                        class="error"
                        *ngIf="
                            f.ssnDigits.invalid &&
                            submitted &&
                            f.ssnDigits.errors &&
                            f.ssnDigits.errors.minlength
                        "
                    >
                        {{ errorMessages.ssnPattern }}
                    </div>
                    <div
                        class="error"
                        *ngIf="
                            f.ssnDigits.invalid &&
                            submitted &&
                            f.ssnDigits.errors &&
                            f.ssnDigits.errors.maxlength
                        "
                    >
                        {{ errorMessages.ssnPattern }}
                    </div>
                </div>
                <!-- BEGIN reCAPTCHA-->
                <div class="form-input-box">
                    <div #divreCAPTCHAcontainer></div>
                    <div class="error" *ngIf="isgTokenInvalid">
                        {{ errorMessages.reCAPTCHARequired }}
                    </div>
                </div>

                <!-- END reCAPTCHA-->
                <div class="av-error">
                    <p [innerHtml]="MultipleProfileMsg"></p>
                </div>

                <div class="btn-wrapper">
                    <button
                        aria-label="search"
                        [disabled]="statusResponse.isLoading"
                        type="submit"
                        (click)="FindMeClick()"
                    >
                        Find me
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                        <span *ngIf="statusResponse.isLoading">
                            <fa-icon
                                [icon]="faCircleNotch"
                                [spin]="true"
                            ></fa-icon>
                        </span>
                    </button>
                    <span class="trouble"
                        >Having trouble? Contact your financial
                        professional.</span
                    >
                </div>
            </form>

            <div class="lpllogo loginbtm">
                <app-footer></app-footer>
            </div>
        </div>
        <div class="loginrightimage col-md-6 col-sm-6 col-xs-12">
            <app-accountviewdesc class="accviewdesc"></app-accountviewdesc>
            <app-footerdesc [showLinks]="false"></app-footerdesc>
        </div>
    </div>
</div>
