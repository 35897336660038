import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-accountviewdesc',
  templateUrl: './accountviewdesc.component.html',
  styleUrls: ['./accountviewdesc.component.scss']
})
export class AccountviewdescComponent implements OnInit {
  @Input() isSelfMigDone: boolean;
  title: string = '';
  discription: string = '';
  link: string = '';
  constructor() { }

  ngOnInit(): void {
    if (this.isSelfMigDone) {
      this.updatePage();
    } else {
      this.existPage();
    }
  }
  updatePage() {
    this.title = 'Welcome to Account View';
    this.discription = 'Account View gives you secure online access to your accounts, statements, documents, and more.'
    this.link = 'Connecting you to your financial accounts anytime, anywhere, on any device.';
  }
  existPage() {
    this.title = 'Welcome to Account View';
    this.discription = 'Account View gives you online access to your accounts, statements, and secure documents. It is also a great way to get access to financial proposals and advice from your financial professional.'
  }

}
