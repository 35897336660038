export const ValidationMessages = {
      emailId: {
        required: 'Email address is required.',
        invalidEmail: 'Please provide a valid email address.',
        notFound: 'Entered  “Email Address” not found, please provide the correct email address on file or call your financial professional for help.'
        
  },
   ssnDigits: {
      required: 'Last 4 digits of SSN is required.',
      minlength: 'Invalid SSN/TIN. It should be last 4 digits.'
  },
    zipCode: {
      required: 'Zip or Postal Code is required.',
      minlength: 'Invalid Zip or Postal Code. It should be exact 5 digits.'
  },
    mobileNumber: {
      required: 'Mobile Number is required.',
      minlength: 'Invalid Mobile Number. It should be 10 digit number.'
    },
  
      system:{
        systemError : 'LPL system is down currently.Please try again or call your financial professional for any help.'
      }

 };
  