import {
    Component,
    OnInit,
    ElementRef,
    ViewChild,
    Renderer2,
    ChangeDetectorRef,
} from '@angular/core';
import { AuthService } from './../services/auth.service';
import { UIStatus } from './../models/models';
import { GetAccessToken, SearchUser, User } from './../models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AppSettings } from 'src/assets/configs/config';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { LoggerService } from 'src/core/services/sharedService/logger.service';
import { CONFIG } from '../services/config.constants';
import { AWSService } from '../services/aws.service';

@Component({
    selector: 'app-self-reg',
    templateUrl: './self-reg.component.html',
    styleUrls: ['./self-reg.component.scss'],
})
export class SelfRegComponent implements OnInit {
    gtoken: any;
    submitted = false;
    faChevronRight = faChevronRight;
    faCircleNotch = faCircleNotch;
    AV2Profile: boolean = false;
    AV1Profile: boolean = false;
    MultipleProfileMsg: any;
    selectedUser: User = new User();
    statusResponse: UIStatus = {
        statusMessage: '',
        errorMessage: '',
        loadingMessage: '',
        isLoading: false,
    };
    myForm: FormGroup;
    subs: Subscription;
    phone = '';
    emailshowmsg = '';
    email: any;
    recaptha = true;
    public isgTokenInvalid: boolean = false;

    emailRegexPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    errorMessages = {
        emailRequired: 'Email is required.',
        emailInvalid: 'Email must be valid.',
        emailMissingAtDot: 'Email must contain “@” and “.”.',
        emailMissingAt: 'Email must contain “@”.',
        emailMissingDot: 'Email must contain “.”.',

        phoneRequired: 'Phone is required.',
        phonePattern: 'Phone must be 10 digits.',

        ssnRequired: 'Last four digits of SSN is required.',
        ssnPattern: 'Last four digits of SSN must be 4 digits.',

        reCAPTCHARequired: 'Please complete reCAPTCHA.',
        system: 'The system is unavailable, please try later.',

        serverAV1:
            "A legacy Account View profile already exists. Please contact your financial professional to complete registration, or <a href='login'> click here to log in.</a>",
        serverAV2:
            "You are already registered. <a href='login'>Click here to log in.</a>",
        serverNoOrMultipleMatch:
            'Your request does not match our records. Please try again.',
        lock: 'Account creation has been locked for security purposes. Please contact your financial professional to complete registration.',
        //"Self-registration has been locked for security purposes. Please contact your financial professional to complete registration."
    };

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private authService: AuthService,
        private loggerService: LoggerService,
        private renderer: Renderer2,
        private cd: ChangeDetectorRef,
        private readonly elementRef: ElementRef,
        private awsService: AWSService
    ) {}

    ngOnInit(): void {
        this.awsService.getAWSFlags().subscribe(flagsApiRedirect => {
            if(flagsApiRedirect !== 'AWS error'){
              this.awsService.setFlagsAwsAPIRedirect(flagsApiRedirect)
            }
        })
        this.myForm = this.fb.group({
            mobileNumber: [
                '',
                [
                    Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/),
                    Validators.required,
                ],
            ],
            emailId: [
                '',
                [
                    Validators.required,
                    Validators.email,
                ],
            ],
            ssnDigits: [
                '',
                [
                    Validators.required,
                    Validators.minLength(4),
                    Validators.maxLength(4),
                ],
            ],
        });

        this.reCAPTCHAloadScript();
    }
    get f() {
        return this.myForm.controls;
    }

    phoneKeyUp() {
        if (this.phone.length >= 14) {
            this.phone = this.phone.slice(0, 14);
        }
    }

    //BEGIN reCAPTCHA
    @ViewChild('divreCAPTCHAcontainer') divreCAPTCHAcontainer: ElementRef;
    private SITE_ID = AppSettings.sitekey;

    reCAPTCHAIsEnabled() {
        if (this.route.snapshot.queryParamMap.get('skipg') == 'true') {
            //this is UI only to allow automation. Backend validation is independent of this setting.
            return false;
        }
        return true;
    }
    // reCAPTCHA Notes
    // 1. Load script with loadCallback
    // 2. render control with succcessCallback
    reCAPTCHAloadScript() {
        if (this.reCAPTCHAIsEnabled() == false) {
            this.loggerService.log('disabled');
            this.gtoken = 'skipg';
            return;
        }

        this.loggerService.group('gRec');

        this.loggerService.log('gReC script reg window Load');

        (window as any).ng2recaptchaloaded = () => {
            this.loggerService.log('gReC 4 onloadCallback');
            this.reCAPTCHArender();
        };

        const script = this.renderer.createElement('script');
        script.src =
            'https://www.google.com/recaptcha/enterprise.js?render=explicit&onload=ng2recaptchaloaded';
        script.defer = true;
        script.async = true;
        script.onload = () => {
            this.loggerService.log('gReC 3 script loaded');
        };
        this.renderer.appendChild(this.elementRef.nativeElement, script);
        this.loggerService.groupEnd();
    }

    reCAPTCHArender() {
        this.loggerService.group('gRec');

        this.loggerService.log('5 loading');
        const grecaptcha = (window as any).grecaptcha;

        if (grecaptcha) {
            grecaptcha.enterprise.render(
                this.divreCAPTCHAcontainer.nativeElement,
                {
                    sitekey: this.SITE_ID,
                    action: 'selfreg',
                    callback: (resonse) => this.reCAPTCHASuccess(resonse),
                    'expired-callback': () => this.reCAPTCHAExpired(),
                }
            );
            this.loggerService.groupEnd();
        } else {
            console.error('reCaptcha missing');
            this.loggerService.groupEnd();

            //alert('Add UI error - system error message');
        }
    }
    reCAPTCHAExpired() {
        this.gtoken = null;
        this.loggerService.log('expired-resetting');
        //this.reCAPTCHAReset();
    }

    reCAPTCHASuccess(data: any) {
        this.loggerService.group('gRec');
        this.loggerService.log('success');
        let me = this;
        if (data) {
            this.loggerService.log('ytoken');
            this.loggerService.log(me.isgTokenInvalid);
            me.gtoken = data;
            me.isgTokenInvalid = false;
            this.loggerService.log('133', me.isgTokenInvalid);
            this.cd.detectChanges();

            this.loggerService.groupEnd();

            // alert('remove error');
        } else {
            const grecaptcha = (window as any).grecaptcha;
            grecaptcha.enterprise.reset();
        }
    }

    reCAPTCHAReset() {
        this.loggerService.log('gRec resetting');
        if (this.reCAPTCHAIsEnabled() == false) {
            this.gtoken = 'skipgreset';
            return;
        }
        this.gtoken = null;
        const grecaptcha = (window as any).grecaptcha;
        grecaptcha.enterprise.reset();
    }

    //END CAPTCHA
    FindMeClick() {
        this.MultipleProfileMsg = '';
        this.loggerService.log('145');
        this.submitted = true;

        if (this.myForm.valid == false) {
            for (let i in this.myForm.controls) {
                this.myForm.controls[i].markAsTouched();
            }
            //Form FAILED - Check gTOKEN
            if (this.checkIfNullOrEmpty(this.gtoken)) {
                this.isgTokenInvalid = true;
                this.loggerService.log('157', this.isgTokenInvalid);
                return;
            } else {
                this.isgTokenInvalid = false;
            }

            return;
        } // End of Form Failed

        //Form SUCCESS - Check gTOKEN
        if (this.checkIfNullOrEmpty(this.gtoken)) {
            this.isgTokenInvalid = true;
            this.loggerService.log('168', this.isgTokenInvalid);
            return;
        } else {
            this.isgTokenInvalid = false;
        }
        this.loggerService.log('170', this.isgTokenInvalid);

        this.statusResponse.isLoading = true;
        this.authService.getAccessTokenString().subscribe(
            (token: GetAccessToken) => {
                if (
                    token == null ||
                    this.checkIfNullOrEmpty(token.access_token)
                ) {
                    this.statusResponse.isLoading = false;
                    this.MultipleProfileMsg = this.errorMessages.system;
                    return;
                }

                let AccessToken = token.access_token;

                //TODO: move to utils.GetPhoneNumber and add test cases
                var phno = '';
                phno = this.myForm.value.mobileNumber;
                phno = phno.replace('(', '');
                phno = phno.replace(')', '');
                phno = phno.replace('-', '');
                phno = phno.replace(' ', '');

                //TODO: move to utils.Getemail and add test cases

                let emailId = '';
                emailId = this.myForm.value.emailId.trim();

                //API Call
                this.subs = this.authService
                    .searchUser(
                        emailId,
                        phno,
                        this.myForm.value.ssnDigits,
                        AccessToken,
                        this.gtoken
                    )
                    .subscribe(
                        (searchResponse: SearchUser) => {
                            this.statusResponse.isLoading = false;
                            this.handleSearchUserResponse(searchResponse, phno);
                        },
                        (error: any) => {
                            // server error for Search User
                            this.statusResponse.isLoading = false;
                            this.MultipleProfileMsg = this.errorMessages.system;
                        }
                    );
            },
            (error: any) => {
                // server error for AuthToken
                this.statusResponse.isLoading = false;
                this.MultipleProfileMsg = this.errorMessages.system;
            }
        );
    }

    //TODO: move to utils and add test cases
    checkIfNullOrEmpty(str: String) {
        if (typeof str === 'undefined' || str == null) return true;
        return str.replace(/\s/g, '').length < 1;
    }

    handleSearchUserResponse(searchResponse: SearchUser, phno) {
        //GToken can be validated ONLY once.
        this.gtoken = null;
        this.reCAPTCHAReset();

        // BEGIN - Simulate Error
        //
        //this.MultipleProfileMsg = this.errorMessages.system;
        //return;
        // END - Simulate Error

        if (searchResponse == undefined || searchResponse == null) {
            // server error
            this.MultipleProfileMsg = this.errorMessages.system;
            return;
        }
        if (searchResponse.Status == 'Success') {
            if (this.checkIfNullOrEmpty(searchResponse.activationGUID)) {
                this.MultipleProfileMsg = this.errorMessages.system;
                return;
            }
            window.localStorage.setItem('emailset', this.myForm.value.emailId);
            window.localStorage.setItem('phno', phno);
            let data = {
                user: searchResponse.user,
                GUID: searchResponse.GUID,
            };
            window.sessionStorage.setItem('searchRes', JSON.stringify(data));
            const guid = searchResponse.activationGUID;
            window.localStorage.setItem(
                CONFIG.lsSelfMigOrSelfRegUser,
                JSON.stringify(searchResponse)
            );
            window.open(
                AppSettings.av2ActivationURL + guid + '&selfreg=true',
                '_self'
            );
            // window.open("https://devint.myaccountviewonline.com/v2/activation?guid=85B6C177-AE77-4FCA-B297-B8D5CC5D4EF9", "_blank");

            return;
        }

        //Missing ErrorCode
        if (this.checkIfNullOrEmpty(searchResponse.Error)) {
            this.MultipleProfileMsg = this.errorMessages.system;
            return;
        }

        //Error scenarios
        switch (searchResponse.Error) {
            case '10001':
                this.isgTokenInvalid = true;
                this.reCAPTCHAReset();
                return;
            case '101':
                this.MultipleProfileMsg = this.errorMessages.serverAV1;
                break;
            case '100':
                this.MultipleProfileMsg = this.errorMessages.serverAV2;
                break;
            case '2005':
                this.MultipleProfileMsg = this.errorMessages.lock;
                break;
            case '102':
            case '1001':
            case '1002':
            case '1003':
            case '1005':
                this.MultipleProfileMsg =
                    this.errorMessages.serverNoOrMultipleMatch;
                break;
            default:
                this.MultipleProfileMsg = this.errorMessages.system;
                break;
        }
    }

    onlyNumberKey(evt) {
        // Only ASCII charactar in that range allowed
        var ASCIICode = evt.which ? evt.which : evt.keyCode;
        if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
        return true;
    }

    changeemail(emailId) {
        if (!emailId) {
            this.emailshowmsg = '';
            return;
        }

        this.email = emailId.trim();

        const result = new RegExp(this.emailRegexPattern, 'g').test(this.email); // here I have used 'g' which means global search

        if (result) {
            this.emailshowmsg = '';
        } else {
            this.emailshowmsg = this.errorMessages.emailInvalid;
            return;
        }
    }
}
