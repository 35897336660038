export enum ExperienceMessageKeys {
    appCopyrightYears = 'appCopyrightYears',
    aWebCongratsTitleSelfMig = 'aWebCongratsTitleSelfMig',
    aWebCongratsMessageSelfMig = 'aWebCongratsMessageSelfMig',
    aWebCongratsTitleAll = 'aWebCongratsTitleAll',
    aWebCongratsMessageAll = 'aWebCongratsMessageAll',
    systemNotAvailable = 'systemNotAvailable',
    enterValidEmail = 'enterValidEmail',
    enterValidPhone = 'enterValidPhone',
    enterValidLast4DigitSSN = 'enterValidLast4DigitSSN'
}
